
import {relativeUrl} from './utils';
import {PrivateRoute} from '../components/util/PrivateRoute/PrivateRoute';
import * as React from 'react';
import {type ReactElement} from 'react';
import {type IndexRouteObject, type RouteObject, type NonIndexRouteObject} from 'react-router';

type RestrictedRouteObject = (IndexRouteObject | NonIndexRouteObject);
export type AppRouteObject = RestrictedRouteObject & {public?: boolean; wildcard?: boolean};


export function makeRoutesRelative(routes: AppRouteObject[], basePath: string): AppRouteObject[] {
  return routes.map((r) => {
    const {...route} = r;
    if(route.path) {
      route.path = relativeUrl(route.path, basePath);
    }
    return route;
  });
}

export function convertToRouteObjects(r: AppRouteObject[], basePath?: string): RouteObject[] {
  return r.map<RouteObject>((route) => {
    const {public: isPublic, wildcard, ...routeObj} = route;
    if(wildcard) {
      routeObj.path += '/*';
    }
    if(!isPublic && routeObj.element) {
      routeObj.element = <PrivateRoute key={routeObj.path}>{routeObj.element}</PrivateRoute>;
    } else if(routeObj.element) {
      routeObj.element = React.cloneElement(routeObj.element as ReactElement, {key: routeObj.path});
    }
    const lazy = routeObj.lazy;
    if(lazy) {
      routeObj.lazy = async () => {
        const wholeLazyRoute = await lazy!();
        const {Component, element, ...lazyRoute} = wholeLazyRoute;
        if(Component && route.path) {
          if(!isPublic) {
             return {...lazyRoute, element: <PrivateRoute key={routeObj.path}><Component /></PrivateRoute>};
          }
          return {...lazyRoute, element: <Component key={route.path} />};
        }
        if(element && route.path) {
          if(!isPublic) {
             return {...lazyRoute, element: <PrivateRoute key={routeObj.path}>{element}</PrivateRoute>};
          }
          return {...lazyRoute, element: React.cloneElement(element as ReactElement, {key: routeObj.path})};
        }
        return wholeLazyRoute;
      };
    }
    if(routeObj.children) {
      routeObj.children = convertToRouteObjects(routeObj.children, basePath);
    }
    return routeObj;
  });
}
