import {combineReducers} from 'redux';
import {type AssetGroupActions, assetGroups} from './assetGroup';
import {type AssetTemplateRevisionActions, assetTemplateRevisions} from './assetTemplateRevision';
import {type ManufacturerActions, manufacturers} from './manufacturers';
import {type DataPointActions, dataPoints} from './dataPoint';
import {type UserActions, userPersistConfig, users} from './user';
import {persistReducer} from 'redux-persist';
import {type RmxBoxActions, rmxBoxes} from './rmxBox';
import {type RmxBoxTypeActions, rmxBoxTypes} from './rmxBoxType';
import {type SiteActions, sites} from './site';
import {type InstallationActions, installations} from './installation';
import {type AssetTypeActions, assetTypes} from './assetType';
import {type AssetRevisionActions} from './installationEntities/assetRevision';
import {type UnitActions, units} from './unit';
import {type UnitGroupActions, unitGroups} from './unitGroup';
import {type UnitGroupTypeActions, unitGroupTypes} from './unitGroupType';
import {type AssetTemplateActions, assetTemplates} from './assetTemplate';
import {type OperationalStatusActions, operationalStatusEntities} from './conversions/operational-statuses';
import {sensorTypes} from './sensorType';
import {interpolationGraphs} from './interpolationGraph';
import {type SPTypeActions, spTypes} from './spType';
import {type SpTemplateActions, spTemplates} from './spTemplate';
import {type SpTemplateRevisionActions, spTemplateRevisions} from './spTemplateRevision';
import {assignableTemplateRevisions} from './assignableTemplateRevision';
import {installationEntities, type InstallationEntitiesActions} from './installationEntities';
import {type AlertTypeActions, alertTypes} from './alertType';
import {type AlertDefinitionActions, alertDefinitions} from './alertDefinition';
import {type AlertGroupActions, alertGroups} from './alertGroup';
import {type AlertConversionActions, alertConversions} from './conversions/alertConversion';
import {type AlertConversionRevisionActions, alertConversionRevisions} from './conversions/alertConversionRevision';
import {type AlertBitByBitAssignmentGroupActions, alertBitByBitAssignmentGroups} from './alertBitByBitAssignmentGroup';
import {
  type AlertBitByBitAssignmentGroupRevisionActions,
  alertBitByBitAssignmentGroupRevisions
} from './alertBitByBitAssignmentGroupRevisions';
import {type RoleActions, roles} from './role';
import {type UserAlertPreferenceActions, userAlertPreferences} from './userAlertPreference';
import {type SystemProcessActions, systemProcesses} from './systemProcess';
import {type TimeZoneActions, timeZones} from './timeZone';
import {companies, type CompanyActions} from './company';
import {type DealerActions, dealers} from './dealer';
import {routers} from 'src/common/redux/entities/router';
import {carriers} from 'src/common/redux/entities/carrier';
import {countries} from 'src/common/redux/entities/country';
import {type ControllerTypeActions, controllerTypes} from 'src/common/redux/entities/controllerType';
import {type ControllerTypeRevisionActions, controllerTypeRevisions} from 'src/common/redux/entities/controllerTypeRevision';
import {type ProtocolAdapterActions, protocolAdapters} from 'src/common/redux/entities/protocolAdapter';
import {type ConnectionKitActions, connectionKits} from 'src/common/redux/entities/connectionKit';
import { componentDefinitions } from './componentDefinition';

export const entities = combineReducers({
  assetTemplateRevisions: assetTemplateRevisions,
  assetTemplates: assetTemplates,
  manufacturers: manufacturers,
  dataPoints: dataPoints,
  assetGroups: assetGroups,
  assetTypes: assetTypes,
  spTypes: spTypes,
  users: persistReducer(userPersistConfig, users) as unknown as typeof users,
  companies: companies,
  sites: sites,
  installations: installations,
  dealers: dealers,
  rmxBoxes: rmxBoxes,
  rmxBoxTypes: rmxBoxTypes,
  units: units,
  unitGroups: unitGroups,
  unitGroupTypes: unitGroupTypes,
  operationalStatus: operationalStatusEntities,
  sensorTypes: sensorTypes,
  interpolationGraphs: interpolationGraphs,
  assignableTemplateRevisions: assignableTemplateRevisions,
  spTemplates: spTemplates,
  spTemplateRevisions: spTemplateRevisions,
  installationEntities,
  alertTypes: alertTypes,
  alertGroups: alertGroups,
  alertDefinitions: alertDefinitions,
  alertConversions: alertConversions,
  alertConversionRevisions: alertConversionRevisions,
  alertBitByBitAssignmentGroups: alertBitByBitAssignmentGroups,
  alertBitByBitAssignmentGroupRevisions,
  roles: roles,
  userAlertPreferences: userAlertPreferences,
  systemProcesses: systemProcesses,
  timeZones: timeZones,
  routers: routers,
  carriers: carriers,
  countries: countries,
  controllerTypes: controllerTypes,
  controllerTypeRevisions: controllerTypeRevisions,
  protocolAdapters: protocolAdapters,
  connectionKits: connectionKits,
  componentDefinitions: componentDefinitions
});

export type Entities  = ReturnType<typeof entities>;

export type EntitiesActions =
  AssetTemplateRevisionActions |
  DataPointActions |
  ManufacturerActions |
  AssetTemplateActions |
  AssetGroupActions |
  AssetRevisionActions |
  AssetTypeActions |
  SPTypeActions |
  CompanyActions |
  DealerActions |
  RmxBoxActions |
  RmxBoxTypeActions |
  SiteActions |
  InstallationActions |
  UnitActions |
  UnitGroupActions |
  UnitGroupTypeActions |
  OperationalStatusActions |
  SpTemplateActions |
  SpTemplateRevisionActions |
  InstallationEntitiesActions |
  AlertTypeActions |
  AlertGroupActions |
  AlertDefinitionActions |
  AlertConversionActions |
  AlertConversionRevisionActions |
  AlertBitByBitAssignmentGroupActions |
  AlertBitByBitAssignmentGroupRevisionActions |
  UserActions |
  RoleActions |
  UserAlertPreferenceActions |
  SystemProcessActions |
  TimeZoneActions |
  ControllerTypeActions |
  ControllerTypeRevisionActions |
  ProtocolAdapterActions |
  ConnectionKitActions;


