import { createRmxAxios } from '../utils/ajax';
import { formatDateShort, parseAspNetDateTime } from '../utils';
import {
  type AssetComponentReset,
  type AssetComponentResetResponse,
  type AssetTypeComponent,
  type ComponentLifeModel,
  type ComponentLifeModelResponse,
  type ComponentLifeResponse,
  type PartVendor,
  type UpsertComponentDto
} from './component-life-types';
import { parseDateTime } from '../../util';
import type { OmitKeys } from '../../common/types/util';

const api = createRmxAxios();

export async function upsertComponent(request: UpsertComponentDto) {
  return parseComponentLifeRow((await api.post<ComponentLifeModelResponse>('/ComponentLife/UpsertComponent', request)).data);
}

export async function toggleComponentLifeEnabled(assetId: number) {
  return parseComponentLifeResponse((await api.post<ComponentLifeResponse>(`/ComponentLife/${assetId}/ToggleComponentLifeEnabled`)).data);
}

export async function addComponentReset(componentId: number, date: Date) {
  return parseComponentLifeRow(
    (await api.post<ComponentLifeModelResponse>('/ComponentLife/AddComponentReset', { componentId: componentId, date: formatDateShort(date) })).data
  );
}

export async function updateReplacementDate(componentId: number, oldDate: Date, newDate: Date) {
  return parseComponentLifeRow(
    (
      await api.post<ComponentLifeModelResponse>('/ComponentLife/UpdateComponentReplacement', {
        componentId: componentId,
        oldDate: formatDateShort(oldDate),
        newDate: formatDateShort(newDate)
      })
    ).data
  );
}

export async function updateFirstChangeDate(componentId: number, newDate: Date) {
  return parseComponentLifeRow(
    (await api.post<ComponentLifeModelResponse>('/ComponentLife/UpdateFirstChangeDate', { componentId: componentId, date: formatDateShort(newDate) })).data
  );
}

export async function snoozeComponent(componentId: number) {
  return parseComponentLifeRow((await api.post<ComponentLifeModelResponse>('/ComponentLife/SnoozeComponent', { componentId: componentId })).data);
}

export async function setupComponent({ componentId, lastChange, nextChange }: { componentId: number; lastChange: string | null; nextChange: string | null }) {
  return parseComponentLifeRow(
    (await api.post<ComponentLifeModelResponse>('/ComponentLife/SetupComponent', { componentId: componentId, nextChange: nextChange, lastChange: lastChange }))
      .data
  );
}

export async function componentLifeDataExists(componentId: number, lastChange: string) {
  const result = (await api.post<string>('/ComponentLife/ComponentLifeDataExists', { componentId: componentId, lastChange: lastChange })).data;
  return result.toLowerCase() === 'true';
}

export async function getAssetTypeComponents(assetId: number) {
  if (!assetId) throw new Error('assetId is required: ' + assetId);
  return (await api.get<AssetTypeComponent[]>(`/ComponentLife/${assetId}/AssetTypeComponents`)).data;
}

export async function getPartVendors(assetId: number) {
  if (!assetId) throw new Error('assetId is required: ' + assetId);
  return (await api.get<PartVendor[]>(`/ComponentLife/${assetId}/PartVendors`)).data;
}

export async function addAssetTypeComponent(name: string, assetId: number, fullLoadHours: number) {
  return (await api.post<AssetTypeComponent>('/ComponentLife/AssetTypeComponent', { name: name, assetId: assetId, fullLoadHours: fullLoadHours })).data;
}

export function parseComponentLifeRow(row: ComponentLifeModelResponse): ComponentLifeModel {
  return {
    ...row,
    formattedNextChangeDate: formatDateShort(parseAspNetDateTime(row.nextChangeDate!)),
    nextChangeDate: parseDateTime(row.nextChangeDate),
    lastChangeDate: parseDateTime(row.lastChangeDate),
    firstChangeDue: parseDateTime(row.firstChangeDue)
  };
}

export function parseComponentLifeRows(rows: ComponentLifeModelResponse[]): ComponentLifeModel[] {
  return rows.map((row) => parseComponentLifeRow(row));
}

export function parseComponentLifeResponse(r: ComponentLifeResponse): ComponentLifeParsedResponse {
  return { ...r, components: parseComponentLifeRows(r.components) };
}

export type ComponentLifeParsedResponse = OmitKeys<ComponentLifeResponse, 'components'> & {
  components: ComponentLifeModel[];
};

export type ComponentId = number;

export async function deleteReplacement(reset: AssetComponentReset) {
  return parseComponentLifeRow(
    (await api.post<ComponentLifeModelResponse>('/ComponentLife/DeleteReplacement', { ...reset, date: formatDateShort(reset.date) })).data
  );
}

export async function archiveComponent(componentId: number) {
  await (
    await api.post('/ComponentLife/ArchiveComponent', { componentId: componentId })
  ).data;
}

export async function getAssetComponentResets(componentId: number): Promise<AssetComponentReset[]> {
  return (await api.get<AssetComponentResetResponse[]>(`/ComponentLife/${componentId}/AssetComponentResets`)).data.map<AssetComponentReset>((r) => ({
    ...r,
    date: parseDateTime(r.date)
  }));
}
