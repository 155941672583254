import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import debounce from 'lodash/debounce';

export type GlobalSearchState = {
  value: string;
  immediateValue: string;
  setValue: (value: string) => void;
};

export function useGlobalSearchState(): GlobalSearchState {
  const input = useMemo(() => document.querySelector('#TileSearchBar input') as HTMLInputElement | null, []);
  const [value, setValueRaw] = useState(input?.value ?? '');
  const [immediateValue, setImmediateValue] = useState('');
  const setValueDebounced = useMemo(() => debounce(setValueRaw, 300), [setValueRaw]);
  const setValue = useCallback((value: string) => {
    setImmediateValue(value);
    setValueDebounced(value);
  }, [setValueDebounced]);

  useEffect(() => {
    function onChange() {
      setValue(input!.value);
    }
    if(input) {
      input.addEventListener('input', onChange);
    }
    return () => {
      if(input)
        input.removeEventListener('input', onChange);
    };
  }, [setValue, input]);
  return { value,immediateValue, setValue: setValue };
}

export function GlobalSearch({state}:{state: GlobalSearchState}) {
  return <Form.Control className='ms-2' type="text" placeholder="Search" value={state.immediateValue} onChange={e => state.setValue(e.currentTarget.value)} />;
}

