import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faPlusCircle,
  faSearch,
  faCaretDown,
  faCaretRight,
  faEye,
  faTrashAlt,
  faEdit,
  faLongArrowAltLeft,
  faArrowLeft,
  faChevronLeft,
  faSignOutAlt,
  faFileUpload,
  faCheck,
  faBars,
  faMinusSquare,
  faTimesCircle,
  faCopy,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faSpinner,
  faUserPlus,
  faUserCog,
  faArchive,
  faUserMinus,
  faUsers,
  faUser,
  faBell,
  faMinus,
  faCog,
  faCogs,
  faFolder,
  faFolderOpen,
  faFile,
  faSitemap,
  faWifi,
  faExclamationTriangle,
  faExclamationCircle,
  faInfo,
  faBellSlash,
  faChartLine,
  faClipboard,
  faUpload,
  faShare, faSort, faSortDown, faSortUp,
  faQuestionCircle,
  faEllipsisV,
  faHourglassHalf,
  faTimes,
  faInfoCircle,
  faCommentDots
} from '@fortawesome/free-solid-svg-icons';
import { faFileCertificate } from '@fortawesome/pro-solid-svg-icons';

export function configureFontAwesome(): void {

  library.add(
    faPlusCircle,
    faSearch,
    faCaretDown,
    faCaretRight,
    faEye,
    faTrashAlt,
    faEdit,
    faLongArrowAltLeft,
    faArrowLeft,
    faChevronLeft,
    faSignOutAlt,
    faFileUpload,
    faCheck,
    faBars,
    faMinusSquare,
    faTimesCircle,
    faCopy,
    faAngleDoubleRight,
    faAngleDoubleLeft,
    faSpinner,
    faUserPlus,
    faUserMinus,
    faUserCog,
    faArchive,
    faUsers,
    faUser,
    faBell,
    faMinus,
    faCog,
    faCogs,
    faFolder,
    faFolderOpen,
    faFile,
    faSitemap,
    faWifi,
    faExclamationTriangle,
    faExclamationCircle,
    faInfo,
    faBellSlash,
    faChartLine,
    faClipboard,
    faUpload,
    faShare,
    faSort,
    faSortDown,
    faSortUp,
    faQuestionCircle,
    faTimes,
    faHourglassHalf,
    faEllipsisV,
    faInfoCircle,
    faFileCertificate,
    faCommentDots
  );
}
