import * as React from 'react';
import {Outlet} from 'react-router';
import NotFound from '../../NotFound/NotFound';
import {applyBasePaths, convertComponentPaths, RoutePaths} from 'src/router/RoutePaths';
import BlankPage from '../BlankPage/BlankPage';
import {type AppRouteObject} from '../../../router/util';
import {lazy} from '../../../util/lazy';

interface CompanyConfigurationRoutesProps {

}
const basePath = RoutePaths.companyConfiguration;

export const companyConfigRoutes: AppRouteObject[] = [
  ...convertComponentPaths(
    {lazy: lazy(import('./CompanyConfigurationRoute'), s => s.SiteEditor), paths: Object.values(RoutePaths.siteConfigurationPaths)},
    {lazy: lazy(import('./CompanyConfigurationRoute'), s => s.InstallationEditor), paths: Object.values(RoutePaths.installationConfigurationPaths)},
    {lazy: lazy(import('./CompanyConfigurationRoute'), s => s.RmxBoxEditor), paths: applyBasePaths(basePath, ...Object.values(RoutePaths.rmxBoxPaths))},
    {lazy: lazy(import('./CompanyConfigurationRoute'), s => s.RmxBoxPortSettingsEditor), paths: applyBasePaths(basePath, ...Object.values(RoutePaths.rmxBoxPortSettingsPaths))},
    {lazy: lazy(import('./CompanyConfigurationRoute'), s => s.RmxBoxSourceEditor), paths: applyBasePaths(basePath, ...Object.values(RoutePaths.rmxBoxSourcePaths))},
    {lazy: lazy(import('./CompanyConfigurationRoute'), s => s.AssetEditor), paths: Object.values(RoutePaths.assetRevisionPaths)},
    {lazy: lazy(import('./CompanyConfigurationRoute'), s => s.SpEditor), paths: Object.values(RoutePaths.spRevisionPaths)},
  ),
  {index: true, element: <BlankPage />},
  {path: '*', element: <NotFound />}
];

const CompanyConfigurationRoutes = (props: CompanyConfigurationRoutesProps) => (
   <Outlet />
);

export default CompanyConfigurationRoutes;
