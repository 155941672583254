export const PortalUrls = {
  CompanyDashboard: `/?p=SiteTiles&pid=:id`,
  SiteDashboard: `?p=InstallationTiles&pid=:id`,
  InstallationDashboard: `?p=InstallationTiles&pid=:id`,
  SystemProcessDashboard: `/?p=InstallationPage&pid=:id`,
  AssetDashboard: `/?p=AssetPage&pid=:id`,
  AssetModuleDashboard: '/?p=ModulePage&pid=:id'
};

export function getPortalUrl(func: (urls: typeof PortalUrls) => string, id: string) {
  return func(PortalUrls).replace(':id', id.toString());
}
