import {regenerateOutputsUuids, type TemplateOutput} from '../../types/TemplateOutput';
import {type Gauge} from '../../types/Gauge';
import {type DataPoint, getDataPointName, remapDataPoint} from '../entities/dataPoint';
import {cast, convertToDropDownOptionByPredicate} from '../../util';
import {type ReferencesTrigger} from '../entities/conversions/alertConversion';
import {removePropertyRecursively} from '../../util/object';
import React, {useMemo} from 'react';
import {immutableSort} from '../../util/array';
import type TemplateSource from 'src/common/types/TemplateSource';
import {type DataPointOption, type DataPointsInput, type TemplateInput, type TestEntity} from 'src/common/types/TemplateInput';
import {getUUID} from 'src/util/uuid';
import {isTriggerWithOneToOneCheck} from 'src/common/redux/entities/conversions/alertConversionRevision';

export interface OrderableDPAssignment extends GeneralDPAssignment {
  order: number;
}

export interface RequiredOrderableDPAssignment extends OrderableDPAssignment {
  required: boolean;
}

export interface GeneralDPAssignment {
  id: number;
  dataPointId: number;
}

export interface DataPointTemplateAssignmentBase extends OrderableDPAssignment {
  outputs: TemplateOutput[];
  gauge: Gauge | null;
  dataPoint: DataPoint | null | undefined;
  alertSetPoints: AlertSetPoint[];
  usesLocalDataPoint: boolean;
  type: DPTAType;
  assetTypeComponentTriggerOverrides: AssetTypeComponentTriggerOverride[];
}

export const regenerateAssignmentUuids = <T extends DataPointTemplateAssignmentBase>(a: T, templateSources: Record<string, TemplateSource>): T =>
  ({
    ...a,
    alertSetPoints: a.alertSetPoints.map<AlertSetPoint>(sp => marshalAlertSetPoint(sp, templateSources)),
    gauge: a.gauge ? cast<Gauge>({
      ...a.gauge,
      maxDataPointTemplateAssignment: a.gauge.maxDataPointTemplateAssignment ?
        regenerateAssignmentUuids(a.gauge.maxDataPointTemplateAssignment, templateSources) : null,
      minDataPointTemplateAssignment: a.gauge.minDataPointTemplateAssignment ?
        regenerateAssignmentUuids(a.gauge.minDataPointTemplateAssignment, templateSources) : null
    }) : null,
    outputs: regenerateOutputsUuids(a.outputs, templateSources)
  });

export const AlertSetPointTypes = [
  'Analog',
  'Manual',
  'DataPoint'
] as const;
export type AlertSetPointType = typeof AlertSetPointTypes[number];

export const ManualAlertSetPointTypes = cast<AlertSetPointType[]>(['Manual', 'Analog']);

export const AlertSetPointComparisonEnum = {
  Greater: 'Greater',
  GreaterOrEqual: 'GreaterOrEqual',
  Lessor: 'Lessor',
  LessorOrEqual: 'LessorOrEqual',
  Equal: 'Equal'
} as const;
export const AlertSetPointComparisons: readonly AlertSetPointComparison[] = Object.values(AlertSetPointComparisonEnum);
export const AlertSetPointAnalogComparisons = [AlertSetPointComparisonEnum.GreaterOrEqual, AlertSetPointComparisonEnum.LessorOrEqual];
export type AlertSetPointComparison = typeof AlertSetPointComparisonEnum[keyof typeof AlertSetPointComparisonEnum];

export interface AlertSetPointOverride extends ContainsDelays {
  alertSetPointLuid: string;
  templateRevisionLuid: string;
  manualValue: number | null;
  manualValue2: number | null;
}

export interface DataPointAlertSetPoints {
  alertSetPoints: AlertSetPoint[];
  templateRevisionLuid: string;
  dataPointName: string;
}

export interface ContainsDelays {
  timerOnDelay: number | null;
  timerOffDelay: number | null;
}

export interface AlertSetPoint extends ReferencesTrigger, ContainsDelays, AlertSetPointDistinction {
  type: AlertSetPointType;
  manualValue: number | null;
  manualValue2: number | null;
  manualValueUnitId: number | null;
  manualValueUnit2Id: number | null;
  dataPointSetPoint: DataPointTemplateAssignmentBase | null;
  dataPointSetPoint2: DataPointTemplateAssignmentBase | null;
  comparison: AlertSetPointComparison;
  comparison2: AlertSetPointComparison | null;
  luid: string;
}

export interface AlertSetPointDistinction {
  alertGroupId: number;
  alertTypeId: number;
}

export function marshalAlertSetPoint(sp: AlertSetPoint, templateSources: Record<string, TemplateSource>) {
  return ({
    ...sp,
    dataPointSetPoint: sp.dataPointSetPoint ? regenerateAssignmentUuids(sp.dataPointSetPoint, templateSources) : null,
    dataPointSetPoint2: sp.dataPointSetPoint2 ? regenerateAssignmentUuids(sp.dataPointSetPoint2, templateSources) : null
  });
}

export type DPTAType = 'DPTA' | 'Trigger';

export interface DataPointTemplateAssignment extends DataPointTemplateAssignmentBase {
  type: 'DPTA';
}

export type AssetTypeComponentTriggerOverride = {
  assetTypeComponentTriggerId: number;
  manualComparisonValue: number|null;
};

export function makeAssetTypeComponentTriggerOverride(componentLifeTriggerId: number): AssetTypeComponentTriggerOverride {
  return {
    assetTypeComponentTriggerId: componentLifeTriggerId,
    manualComparisonValue: null
  };
}

export interface TriggerDPTA extends DataPointTemplateAssignmentBase  {
  type: 'Trigger';
  dataPoint: DataPoint;
}

export const TriggersContext = React.createContext<DataPoint[] | undefined>(undefined);
export const DPTATypeContext = React.createContext<DPTAType | undefined>(undefined);
export function useTriggerDropDownOptions(
  triggerDataPoints: DataPoint[] | undefined,
  currentSelectedTriggerUuid: string | null,
  usedTriggerDataPoints: DataPoint[]) {
  return useMemo(() => {
      if (triggerDataPoints === undefined) {
        return [];
      }
      const currentOption = triggerDataPoints.find(t => t.uuid === currentSelectedTriggerUuid);
      const dpsForOptions = triggerDataPoints.filter(t => !usedTriggerDataPoints.includes(t));
      if (currentOption && usedTriggerDataPoints.length !== 0) {
        dpsForOptions.push(currentOption);
      }

      return convertToDropDownOptionByPredicate(dpsForOptions, dp => getDataPointName(dp), dp => dp.uuid!);
    },
    [triggerDataPoints, usedTriggerDataPoints, currentSelectedTriggerUuid]
  );
}
export function remapDataPoints<T extends DataPointTemplateAssignmentBase>(triggers: T[]): TriggerRemapResult<T> {
  triggers = removePropertyRecursively(triggers, 'id');
  const dpsDictionary = triggers.map(t => t.dataPoint)
    .reduce<Record<string, DataPoint>>((l, c) => ({...l, [c!.uuid!]: remapDataPoint(c!)}), {});
  const remappedDps = triggers.map(trigger => {
    const val = cloneDataPointTemplateAssignment(cast<T>({
    ...trigger,
    dataPoint: dpsDictionary[trigger.dataPoint!.uuid!]
    }));
    if(isTriggerWithOneToOneCheck(val)) {
      val.alertOneToOneCheck.triggerUuid = val.dataPoint.uuid!;
    }
    return val;
  });
  return {dpsDictionary: dpsDictionary, remappedDps: remappedDps};
}


export function cloneDataPointTemplateAssignment<T extends DataPointTemplateAssignmentBase>(assignment: T): T {
  return {
    ...assignment,
    outputs: assignment.outputs.map(cloneTemplateOutput)
  };
}

export function cloneTemplateOutput(output: TemplateOutput): TemplateOutput {
  return {
    ...output,
   inputs: output.inputs.map(cloneTemplateInput)
  };
}

export function cloneTemplateInput(input: TemplateInput): TemplateInput {
  return {
    ...input,
    dataPointsInput: input.dataPointsInput ? cloneDataPointsInput(input.dataPointsInput) : null,
    uuid: getUUID()
  };
}

export function cloneDataPointsInput(input: DataPointsInput): DataPointsInput {
  const optionsDic = input.dataOptions
    .reduce<Record<string, DataPointOption>>((l, c) => ({...l, [c.uuid!]: cloneDataOption(c)}), {});
  return {
    ...input,
    dataOptions: Object.values(optionsDic),
    filterOptions: input.filterOptions.map(cloneDataOption),
    testEntities: input.testEntities.map(e => cloneTestEntity(e, optionsDic))
  };
}

export function cloneTestEntity(testEntity: TestEntity, dic: Record<string, DataPointOption>): TestEntity {
  return {
    ...testEntity,
    testValues: testEntity.testValues.map((v) => ({
      ...v,
      dataOptionUuid: dic[v.dataOptionUuid].uuid
    }))
  };
}

export function cloneDataOption<T extends DataPointOption>(option: T):  T {
  return {
    ...option,
    uuid: getUUID()
  };
}
interface TriggerRemapResult<T extends DataPointTemplateAssignmentBase = TriggerDPTA> {
  dpsDictionary: Record<string, DataPoint>;
  remappedDps: T[];
}

export const isTriggerDPTA = (dpta: DataPointTemplateAssignmentBase): dpta is TriggerDPTA => dpta.type === cast<DPTAType>('Trigger');

export interface RegisterAssignment extends GeneralDPAssignment {
  outputs: TemplateOutput[];
  gauge: object | null;
}

export type AssignmentWithOutputs = DataPointTemplateAssignment | RegisterAssignment;

export function sortAssignmentsAscending(assignments: OrderableDPAssignment[]) {
  return immutableSort(assignments, (a, b) => a.order > b.order ? 1 : -1);
}

export function sortTemplateAssignmentsAscending(assignments: OrderableDPAssignment[]) {
  return immutableSort(assignments, (a, b) => a.id > b.id ? 1 : -1);
}

/**
 * Re-indexes the order property of the assignments to be in ascending order and returns the new array.
 * This is used to save the order of the array in the backend. The frontend changes the order of the array when the backend can only sort by the order property.
 * @param dataPointAssignments
 */
export function reindexAssignmentOrder<T extends OrderableDPAssignment>(dataPointAssignments: T[]) {
  return dataPointAssignments.map((value: OrderableDPAssignment, index) => ({...value, order: index}) as T);
}
