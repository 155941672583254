import {type AssetTemplateForm, type AssetTemplateRevision} from '../../redux/entities/assetTemplateRevision';
import {type DataPoint, type DataPointType, getDataPointName} from '../../redux/entities/dataPoint';
import {
  type AdcConversion,
  type BitOrder, type DataPointFilterOption, type DataPointInput, type DataPointOption,
  type DataPointsInput,
  type DataType,
  DataTypes, EDataPointInputReferenceType,
  EOSInputLogicOptions,
  type InputType,
  type ModBusInput,
  type OSInput,
  type OutputInput, type SpecInput,
  type TemplateInput,
  type TemplateInputDefaultProperties, type TestEntity, type TestEntityValue
} from '../../types/TemplateInput';
import {convertToVariableName} from '../string';
import {type TemplateOutput} from '../../types/TemplateOutput';
import {type AssetTemplate, type AssetTemplateFormFields} from '../../redux/entities/assetTemplate';
import {cast} from '../index';
import {type DataPointTemplateAssignment, type TriggerDPTA} from '../../redux/abstract/dataPointAssignment';
import {makeDPTemplateAssignmentTemplate} from '../../redux/abstract/dataPointTemplate';
import {makeAlertOneToOneCheck, makeAlertTriggerDp, makeDelays, makeManufacturerAlertCodeDp} from './index';
import {getUUID} from 'src/util/uuid';
import {type TriggerWithOneToOneCheck} from 'src/common/redux/entities/conversions/alertConversionRevision';

function makeTemplateInputDefaultProperties(variableName: string): TemplateInputDefaultProperties {
  return {
    id: 0,
    variableName: convertToVariableName(variableName),
    templateSourceUuid: '',
    modBusInput: null,
    adcConversion: null,
    osInput: null,
    unitId: null,
    digitalInput: null,
    dataPointsInput: null,
    dataPointInput: null,
    specInput: null,
    uuid: getUUID(),
    testValue: 0
  };
}

export function makeTemplateInput(type: InputType, variableName: string): TemplateInput {
  switch (type) {
    case 'ModBus': return makeModBusInputType(variableName);
    case 'ModBusRawAdc': return makeModBusInputType(variableName, true);
    case 'DataPoints': return makeDataPointsInputType(variableName);
    case 'DataPoint': return makeDataPointInputType(variableName);
    case 'Output': return makeOutputInputType(variableName);
    case 'Digital': return makeDigitalInputType(variableName);
    case 'Analog': return makeAnalogInputType(variableName);
    case 'OperationalStatus': return makeOSInputType(variableName);
    case 'Spec': return makeSpecInputType(variableName);
    default: throw new Error('Input type not valid: ' + type);
  }
}

export function makeModBusInputType(variableName: string, rawAdc: boolean = false): TemplateInput {
  return {
    ...makeTemplateInputDefaultProperties(variableName),
    type: rawAdc ? 'ModBusRawAdc' : 'ModBus',
    modBusInput: makeModBusInput(3, 0, 0, DataTypes.S16, null),
    adcConversion: rawAdc ? makeAdcConversion() : null
  };
}

export function makeAnalogInputType(variableName: string): TemplateInput {
  return {
    ...makeTemplateInputDefaultProperties(variableName),
    type: 'Analog',
    adcConversion: makeAdcConversion()
  };
}

export function makeOSInputType(variableName: string): TemplateInput {
  return {
    ...makeTemplateInputDefaultProperties(variableName),
    type: 'OperationalStatus',
    osInput: makeOSInput()
  };
}

export function makeSpecInputType(variableName: string): TemplateInput {
  return {
    ...makeTemplateInputDefaultProperties(variableName),
    type: 'Spec',
    specInput: makeSpecInput()
  };
}

export const makeSpecInput = (): SpecInput => ({specDataPointId: 0});
export function makeOSInput(osDPId: number = 0): OSInput {
  return {
    logic: EOSInputLogicOptions.All,
    osDataPointId: osDPId,
    condition: true
  };
}

export function makeDigitalInputType(variableName: string): TemplateInput {
  return {
    ...makeTemplateInputDefaultProperties(variableName),
    type: 'Digital',
    digitalInput: {
      useAlertValue: false,
      timerOff: null,
      timerOn: null
    }
  };
}

export function makeModBusInput(
  modbusFunction: number, register: number, decimals: number, dataType: DataType, bitOrder: BitOrder): ModBusInput {
  return {
    modbusFunction: modbusFunction,
    register: register,
    displayRegisterInHex: false,
    decimals: decimals,
    dataType: dataType,
    bitOrder: bitOrder
  };
}

export function makeAdcConversion(): AdcConversion {
  return {
    response: 'Linear',
    adcBits: 12,
    interpolationGraphId: null,
    sensorMax: null,
    sensorMin: null,
    sensorTypeId: 0,
    optionalResistance: null,
    internalResistance: undefined
  };
}

export function makeDataPointsInputType(variableName: string): TemplateInput {
  return {
    ...makeTemplateInputDefaultProperties(variableName),
    type: 'DataPoints',
    dataPointsInput: makeDataPointsInput()
  };
}

export function makeDataPointsInput(): DataPointsInput {
  return {
    sourceType: '' as unknown as DataPointsInput['sourceType'],
    filterOptions: [],
    dataOptions: [],
    testEntities: [],
    assetGroupIdFilter: null
  };
}

export function makeDataPointInputType(variableName: string): TemplateInput {
  return {
    ...makeTemplateInputDefaultProperties(variableName),
    type: 'DataPoint',
    dataPointInput: makeDataPointInput()
  };
}

export function makeDataPointInput(): DataPointInput {
  return {
    dataPointId: 0,
    alertGroupId: null,
    referenceType: EDataPointInputReferenceType.DataPoint
  };
}

export function makeDataPointOption(): DataPointOption {
  return {
    dataPointType: 'Data',
    dataPointId: 0,
    alertGroupId: null,
    uuid: getUUID()
  };
}

export function makeDataPointFilterOption(): DataPointFilterOption {
  return {
    dataPointType: 'OperationalStatusField',
    dataPointId: 0,
    alertGroupId: null,
    uuid: getUUID(),
    value: 1
  };
}

export function makeTestEntity(dataOptions: DataPointOption[]): TestEntity {
  return {
    testValues: dataOptions.map(dOption => makeTestEntityValue(dOption))
  };
}

export function makeTestEntityValue(dataOption: DataPointOption): TestEntityValue {
  return {
    dataOptionUuid: dataOption.uuid,
    testValue: 0
  };
}

export function makeOutputInputType(variableName: string): TemplateInput {
  return {
    ...makeTemplateInputDefaultProperties(variableName),
    type: 'Output',
    outputInput: makeOutputInput()
  };
}

export function makeOutputInput(): OutputInput {
  return {
    outputUuid: ''
  };
}

export function makeAssetTemplateRevision(
  assetTemplateId: number,
  revisionTitle: string,
  revisionDescription: string,
): AssetTemplateRevision {
  return ({
    id: 0,
    consecutiveRegisterPollingOnly: false,
    revisionTitle: revisionTitle,
    revisionDescription: revisionDescription,
    assetTemplateId: 0,
    ...makeDPTemplateAssignmentTemplate(),
    osConversionAssignments: [],
    alertConversionAssignments: [],
    modules: [],
    assetModuleName: null,
    parentRevisionId: null
  });
}

export function makeAssetTemplateFormFields(template?: AssetTemplate): AssetTemplateFormFields {
  return {
    name: template?.name || '',
    assetGroupId: template?.assetGroupId || 0,
    assetTypeId: template?.assetTypeId ?? null,
    manufacturerIds: template?.manufacturerIds || [],
    containsAssetModules: template?.containsAssetModules || false,
    controllerTypeRevisionIds: template?.controllerTypeRevisionIds || []
  };
}

export function makeAssetTemplateForm(template?: AssetTemplateFormFields, revision?: AssetTemplateRevision): AssetTemplateForm {
  return {
    template: template || makeAssetTemplateFormFields(),
    revision: revision || makeAssetTemplateRevision(0, 'Create Asset Template', '')
  };
}

export function makeDataPointTemplateAssignment(
  dataPoint: DataPoint,
  order: number,
  required?: boolean,
  primary?: boolean): DataPointTemplateAssignment {
  return {
    id: 0,
    dataPointId: dataPoint.id,
    order: order,
    gauge: null,
    outputs: [makeOutput(primary || false, getDataPointName(dataPoint), dataPoint)],
    type: 'DPTA',
    dataPoint: null,
    alertSetPoints: [],
    usesLocalDataPoint: false,
    assetTypeComponentTriggerOverrides: [],
  };
}

export function makeManufacturerAlertCodeDataPointTemplateAssignment(
  name: string,
  manufacturerUnitGroupId: number,
  order: number): DataPointTemplateAssignment {
  const dp = makeManufacturerAlertCodeDp(name, manufacturerUnitGroupId);
  return {
    id: 0,
    dataPointId: dp.id,
    order: order,
    gauge: null,
    outputs: [makeOutput(true, getDataPointName(dp), dp)],
    type: 'DPTA',
    dataPoint: dp,
    alertSetPoints: [],
    usesLocalDataPoint: true,
    assetTypeComponentTriggerOverrides: [],
  };
}

export function makeAlertStatusAssignment(
  dp: DataPoint,
  order: number): DataPointTemplateAssignment {
  return {
    id: 0,
    dataPointId: dp.id,
    order: order,
    gauge: null,
    outputs: [makeOutput(true, getDataPointName(dp), dp)],
    type: 'DPTA',
    dataPoint: dp,
    alertSetPoints: [],
    usesLocalDataPoint: true,
    assetTypeComponentTriggerOverrides: []
  };
}


export function makeTriggerDPTA(name: string, order: number, triggerUnitGroupId: number): TriggerDPTA {
  return {
    id: 0,
    dataPoint: makeAlertTriggerDp(name, triggerUnitGroupId),
    dataPointId: 0,
    order: order,
    gauge: null,
    outputs: [makeOutput(true, name, undefined, true)],
    type: 'Trigger',
    alertSetPoints: [],
    usesLocalDataPoint: true,
    assetTypeComponentTriggerOverrides: []
  };
}

export function makeTriggerWithAlertOneToOneCheck(name: string, order: number, triggerUnitGroupId: number): TriggerWithOneToOneCheck {
  const trigger = makeTriggerDPTA(name, order, triggerUnitGroupId);
  return {
    ...trigger,
    alertOneToOneCheck: makeAlertOneToOneCheck(trigger.dataPoint.uuid!)
  };
}

export function makeOutput(primary: boolean, variableName: string, dataPoint?: DataPoint, isTrigger?: boolean): TemplateOutput {
  const type = dataPoint?.dataPointType || cast<DataPointType>('Data');
  return {
    name: 'Primary Output',
    primary: primary,
    inputs: [{
      ...makeTemplateInputDefaultProperties(variableName),
      type: type === 'OperationalStatusField' ? 'OperationalStatus' : 'ModBus',
      modBusInput: type !== 'OperationalStatusField' ? makeModBusInput(3, 0, 0, DataTypes.S16, null) : null,
      osInput: type === 'OperationalStatusField' ? makeOSInput(dataPoint?.id || 0) : null
    }],
    calculation: null,
    testTimeStampInput: null,
    calculationTestOutput: null,
    convertToBoolean: !!isTrigger,
    bitNumber: null,
    comparisonValue: null,
    resultingValue: null,
    comparisonType: null,
    ...makeDelays()
  };
}
