import { DarkSelect } from '../../shared-compat/utils/DarkSelect/DarkSelect';
import React, { useState } from 'react';
import { EUserGroups } from '../Service.types';
import { ButtonFullWidth, FormRow } from './RmxViewFlag';
import type { ModalState } from '../../hooks/useModal';
import { GenericModal } from '../../utils/GenericModal/GenericModal';
import { getPortalServiceApi } from '../api/portal-api';
import { GrowingTextArea } from 'src/portal/utils/GrowingTextArea';

export type CreateFlagRequest = { siteUuid?: string; activityId?: string; assetId?: number };

interface Props {
  state: ModalState<CreateFlagRequest>;
  refetchFlagTable?: () => void;
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    openCreateFlagModal: (f: CreateFlagRequest) => void;
  }
}

export function RmxCreateFlag({ state, refetchFlagTable }: Props) {
  const api = getPortalServiceApi();
  const [flagDescription, setFlagDescription] = useState('');
  const [userGroup, setUserGroup] = useState<string>('Select');
  const [loading, setLoading] = useState(false);

  const options = [
    { value: 'Select', label: 'Select' },
    { value: EUserGroups.Support, label: 'Support' },
    { value: EUserGroups.Orders, label: 'Sales/Orders' }, // sales are to go to orders in the crm this is because the crm does not have a sales flag group
    { value: EUserGroups.Accounting, label: 'Accounting' }
  ];

  const handleCreateFlag = async () => {
    setLoading(true);
    try {
      if (userGroup === 'Select') {
        alert('Please select a user group');
      } else {
        await api.createFlag({
          activityId: state.param?.activityId,
          assetId: state.param?.assetId,
          siteUuid: state.param?.siteUuid,
          userGroup: userGroup,
          reason: flagDescription
        });
        state.setIsOpen(false);
        refetchFlagTable?.();
      }
    } catch (error) {
      console.error('Error creating flag:', error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <GenericModal open={state.isOpen} setIsOpen={state.setIsOpen} label='New Flag' showClose={true}>
      <FormRow label='Reason' content={<GrowingTextArea value={flagDescription} setValue={setFlagDescription} readOnly={false} />} />
      <FormRow label='User Group' content={<DarkSelect options={options} value={userGroup} onChange={setUserGroup} />} />
      <ButtonFullWidth variant='secondary' onClick={handleCreateFlag} disabled={loading}>
        {loading ? 'Creating...' : 'Create'}
      </ButtonFullWidth>
    </GenericModal>
  );
}
