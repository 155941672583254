import * as React from 'react';
import {Outlet} from 'react-router';
import NotFound from '../../NotFound/NotFound';
import {
  convertComponentPaths,
  RoutePaths
} from 'src/router/RoutePaths';
import {type AppRouteObject} from '../../../router/util';
import {lazy} from '../../../util/lazy';

interface UserManagementRoutesProps {

}

export const userRoutes: AppRouteObject[] = [
  ...convertComponentPaths(
    {lazy: lazy(import('./pages/UserEditor/UserEditor'), s => s.default), paths: Object.values(RoutePaths.userManagementPaths)},
  ),
  {index: true, lazy: lazy(import('./pages/UserDataGrid/UserDataGrid'), s => s.default)},
  {path: '*', element: <NotFound />}
];

const UserManagementRoutes = (_: UserManagementRoutesProps) => (
    <Outlet/>
);

export default UserManagementRoutes;
