// eslint-disable-next-line @typescript-eslint/naming-convention
import jwt_decode from 'jwt-decode';
import type { AuthToken } from '../../common/types/AuthToken';
import { getApiKey } from './ajax';

export function formatDateShort<T extends Date | null>(date: T): T extends Date ? string : null {
  if (date === null) return null as T extends Date ? string : null;
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  return `${month}/${day}/${year}` as T extends Date ? string : null;
}

export function formatDateOnlyIso<T extends Date | null>(date: T): T extends Date ? string : null {
  if (date === null) return null as T extends Date ? string : null;
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  return `${year}-${month}-${day}` as T extends Date ? string : null;
}

type ParsedResult<TString extends string | null | undefined> = TString extends null ? null : TString extends undefined ? null : Date;
export function parseAspNetDateTime<TString extends string | null | undefined>(dateStr: TString): ParsedResult<TString> {
  if (dateStr === undefined || dateStr === null) return null as ParsedResult<TString>;
  if (typeof dateStr !== 'string') {
    console.error('dateStr must be a string: ', dateStr);
    throw new Error('dateStr must be a string: ' + dateStr);
  }
  return new Date(parseInt(dateStr.replace('/Date(', '').replace(')/', ''), 10)) as ParsedResult<TString>;
}

export function parseShortDateString(dateStr: string): Date;
export function parseShortDateString(dateStr: null): null;
export function parseShortDateString(dateStr: string | null) {
  if (dateStr === null) return null;
  const parts = dateStr.split('/');
  if (parts.length < 3) throw new Error('Invalid date string: ' + dateStr);
  return new Date(parseInt(parts[2]!), parseInt(parts[0]!) - 1, parseInt(parts[1]!));
}

export function combine(...arr: Array<any | string | undefined | null>): string {
  return arr.filter((val) => !!val).join(' ');
}
export function pipeLog<T>(label: string, val: T, enable = true) {
  if (enable) console.log(label, val);
  return val;
}
export { ContextProvider } from './ContextProvider';
export { createContextWithValue } from './ContextProvider';

export const numberOrNull = (value: any) => numberOr(value, null);

export const numberOrUndefined = (value: any) => numberOr(value, undefined);

export const numberOr = <T>(value: any, defaultValue: T) => {
  if (value === null || value === undefined || (typeof value === 'string' && value.trim() === '')) {
    return defaultValue;
  }
  const num = Number(value);

  if (isNaN(num)) {
    return defaultValue;
  }
  return num;
};

export function parseIntOrNull(val: string): number | null {
  if (val.trim() === '') return null;
  const value = parseInt(val);
  if (isNaN(value)) return null;
  return value;
}

export function throwErr<T>(mssg: string): T {
  throw new Error(mssg);
}

export const stringOrNull = (value: string) => {
  if (value === null || value === undefined) return null;
  if (value.trim() === '') return null;
  return value;
};

export function transformOr<T, R>(value: T, transform: (value: Exclude<T, null | undefined>) => R, defaultValue: R) {
  if (value === null || value === undefined) return defaultValue;
  return transform(value as Exclude<T, null | undefined>);
}

export function capitalizeEachWord(str: string) {
  return str
    .split(/\s+/)
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(' ');
}

export function valueOrNA(value: number | string | null | undefined) {
  return isNullOrUndefined(value) ? 'N/A' : String(value);
}

export function isNullOrUndefined<T>(v: T | null | undefined): v is null | undefined {
  return !isNotNullOrUndefined(v);
}

export function isNotNullOrUndefined<T>(v: T | null | undefined): v is T {
  return v !== undefined && v !== null;
}

export function arrayToRecord<T, K extends string | number, V>(
  arr: T[],
  getKey: (i: T) => K,
  getValue: (i: T) => V
): K extends string ? Record<string, V> : Record<number, V> {
  const obj: Record<string | number, V> = {};
  for (const item of arr) {
    obj[getKey(item)] = getValue(item);
  }
  return obj as K extends string ? Record<string, V> : Record<number, V>;
}
export function range(start: number, stop: number, step: number = 1) {
  return Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);
}

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getUserInformation(token: string) {
  return jwt_decode<AuthToken>(token);
}

export function getMapUrl(address: string) {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const formattedAddress = address.replace(/\s/g, '+');
  let url: string;

  if (isIOS) {
    url = `https://maps.apple.com/?address=${formattedAddress}`;
  } else {
    // Default to Google Maps
    url = `https://www.google.com/maps/search/?api=1&query=${formattedAddress}`;
  }
  return url;
}

export function assert(condition: unknown, msg?: string): asserts condition {
  if (!condition) {
    throw new Error(msg);
  }
}

export function formatCurrency(value: number) {
  return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export function hasRole(requiredRole: string) {
  const userInformation = getUserInformation(getApiKey()!);
  // @ts-ignore
  const userRoles = userInformation?.role as string | string[];
  if (typeof userRoles === 'string') {
    return userRoles === requiredRole;
  }

  return userRoles.includes(requiredRole);
}

export function getParams() {
  const searchParams = new URL(window.location.href).searchParams;
  const assetId = numberOrNull(searchParams.get('a'));
  const siteId = numberOrNull(searchParams.get('s'));

  return {
    assetId,
    siteId
  };
}

export function formatPercentage(value: number) {
  return `${value.toFixed(2)}%`;
}
