import React, {type ComponentType, useCallback, useState, Suspense} from 'react';
import styles from './PageHeader.module.scss';
import {Link} from 'src/router';
import {RoutePaths} from 'src/router/RoutePaths';
import IconButton from '../../util/widgets/IconButton/IconButton';
import {AppTheme, getLogoLink} from 'src/appTheme';
import {PageContainer} from 'src/components/layout/PageContainer/PageContainer';
import {combineClasses} from 'src/util';
import { type OmitKeys } from 'src/common/types/util';

export interface HeaderChangeProp {
  changeHeader: (lable: string) => void;
}

export interface PageHeaderProps {
  label?: string;
  removeMargin?: boolean;
  children?: any;
}

const HeaderLink = ({to, text, className}: {to: string; text: string; className?: string}) =>
  (<Link className={combineClasses(className, styles['header-link'])} to={to}>{text}</Link>);

export function PageHeader(props: PageHeaderProps) {
  return (
    <PageContainer>
      <PageHeaderBase {...props} />
      {props.children}
    </PageContainer>
  );
}

export type PageHeaderWrapperProps = OmitKeys<PageHeaderProps, 'label'> & {
  page: ComponentType<HeaderChangeProp>;
};

export function PageHeaderWrapper(props: PageHeaderWrapperProps) {
  const [label, setLabel] = useState('');
  const onChangeHeader = useCallback((labelParam: string) => setLabel(labelParam), []);
  return (
    <PageContainer>
      <PageHeaderBase {...props} label={label} />
      <Suspense fallback={<div/>}>
        <props.page changeHeader={onChangeHeader} />
      </Suspense>
    </PageContainer>
  );
}

export function PageHeaderBase(props: PageHeaderProps) {
  const marginStyle = props.removeMargin ? {} : {marginBottom: '0.5rem'};
  return (
    <div className={styles['header']} style={{...AppTheme.styles.background, ...marginStyle, minHeight: 63 }}>
      <div className={styles['logo-container']}>
        <Link to={RoutePaths.home}>
          <img src={getLogoLink()} alt='Uptime RMX' style={{marginRight: '1rem', backgroundColor: 'red'}}  />
        </Link>
        {props.label && <h4 className={combineClasses(styles['hide-on-mobile'], styles['page-title'])} style={{marginBottom: 0}}>{props.label}</h4>}
      </div>
      <div className={styles['links-container']}>
        <HeaderLink className={styles['hide-on-mobile']} to={RoutePaths.dashboard} text='Dashboard'/>

        <HeaderLink className={styles['hide-on-mobile']} to={RoutePaths.globalConfiguration} text='Global Configuration'/>
        <HeaderLink className={styles['show-on-mobile']} to={RoutePaths.globalConfiguration} text='Global'/>

        <HeaderLink className={styles['hide-on-mobile']} to={RoutePaths.companySelection} text='Company Configuration'/>
        <HeaderLink className={styles['show-on-mobile']} to={RoutePaths.companySelection} text='Company'/>

        <HeaderLink className={styles['hide-on-mobile']} to={RoutePaths.userManagement} text='User Management'/>
        <IconButton className={styles['icon-button']} icon='user' link={RoutePaths.profilePaths.View} size={'2x'} />
        <IconButton className={styles['icon-button']} icon='sign-out-alt' link={RoutePaths.logout} size={'2x'} />
      </div>
    </div>
  );
}
