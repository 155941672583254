import React, {useEffect} from 'react';
// tslint:disable-next-line:no-import-side-effect
import 'stacktrace-js';
import {reportException} from 'src/util/sentryProvider';
import { useRouteError } from 'react-router';
import {useExecuteOnMount} from '../../hooks/useMount';


export function ReactRouterErrorBoundary() {
  const error = useRouteError();
  console.log('error boundary');
  useExecuteOnMount(() => {
     reportException(error);
  });
  const [stack, setStack] = React.useState<string[] | undefined>(undefined);
   useEffect(() => {
     async function loadStackFrame() {
       try {
         if(error instanceof Error) {
           // eslint-disable-next-line no-undef
            const stackFrames = await StackTrace.fromError(error);
            setStack(stackFrames.map((sf) => sf.toString()));
         } else if(typeof error === 'string') {
            setStack([error]);
         } else {
            setStack(['Failed to generate stack trace']);
         }

       } catch (err) {
         setStack(['Failed to generate stack trace - ' + err]);
       }
     }
     void loadStackFrame();
   }, [error]);

    console.error('From ErrorBoundary: ', error);

   return (
        <div className='mt-5 m-auto' style={{maxWidth: 600}}>
          <h3 style={{textAlign: 'center', color: 'red'}}>Something went wrong. Procura has been notified.</h3>
          {String(error)}
          {stack !== undefined
            ? stack.map((line, index) => <div key={index}>{line}</div>)
            : <div>Loading Stack Trace...</div>}
        </div>
      );
}
