import {combineReducers} from 'redux';
import {convertToDropDownOptions, groupBy, setPropertyRecursively} from '../../../util';
import {type CommonDispatch, type CommonState} from '../../index';
import {type CurrentSpec, type InstallationModel, type InstallationModelRevision, type InstallationModelStatus} from './index';
import {
  dispatchInstallationRevisionEntityActions,
  getInstallationRevisionEntityDeleteActions,
  getInstallationRevisionEntityUpsertActions,
} from './common';
import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../../utils';
import {createStandardSelectors, getInstallationEntities, selector} from '../../selectors';
import {createAction, createReducer} from 'typesafe-actions';
import {
  deleteAssetRevision, type DeleteInstallationRevisionEntityResponse,
  saveAssetRevision,
  type SaveInstallationRevisionEntityResponse
} from 'src/api/companyConfigApi';
import {filterRelatedHidden} from 'src/common/types/Hidable';
import {type AlertStatusInfo} from '../../../../api/userDashboardApi';
import {type IExternalIntId} from 'src/common/redux/abstract/IExternalIntId';
import {getUUID} from 'src/util/uuid';

export interface UserViewAsset extends SimpleUserViewAsset {
  assetGroupName: string;
  manufacturerName: string;
}

export interface SimpleUserViewAsset extends AlertStatusInfo {
  id: number;
  uuid: string;
  name: string;
  isMonitored: boolean;
  assetGroupName: string;
  manufacturerName: string;
  indicator: string;
  opStatusColor: string;
  modelNumber: string;
  serialNumber: string;
  notes: string | null;
}

export interface AssetRevision extends InstallationModelRevision {
  asset: Asset;
  assetId?: number;
  parentAssetRevisionId?: number;
  assetTemplateId: number;
  assetTemplateRevisionId: number;
  controllerTypeRevisionId: number | null;
}

export interface Asset extends InstallationModel, IExternalIntId {
  parentAssetId: number | null;
  isAssetModule: boolean;
  assetModules: Asset[];
  assetGroupId: number | null;
  manufacturerId: number | null;
  isHidden: boolean;
  assetTypeId: number | null;
}

export interface AssetStatus extends InstallationModelStatus {
  asset: Asset;
}

export function isAssetStatus(status: InstallationModelStatus): status is AssetStatus {
  return 'asset' in status;
}
export function isAssetRevision(revision: InstallationModelRevision): revision is AssetRevision {
  return 'asset' in revision || 'assetId' in revision;
}
const cloneActions = {
  clear: createAction('CLEAR_ASSET_TO_CLONE')(),
  set: createAction('SET_ASSET_TO_CLONE')<AssetRevision>()
};
type AssetToCloneActions = GetActions<typeof cloneActions>;

const actions = {
  ...createStandardActions(placeholder<AssetRevision>(), 'ASSET_REVISION/SET', 'ASSET_REVISION/SAVE')
};
export const deleteAssetRevisionAction = createAction('SP_REVISION/DELETE')<number>();
export type AssetRevisionActions = GetActions<typeof actions> | ReturnType<typeof deleteAssetRevisionAction>;
const selectors = createStandardSelectors(placeholder<AssetRevision>(), s => getInstallationEntities(s).assetRevisions);

export const assetRevisions = combineReducers({
  items: standardItemsReducer<AssetRevision, AssetRevisionActions>(actions)
    .handleAction(deleteAssetRevisionAction, (state, action) => {
      const newState = {...state};
      delete newState[action.payload];
      return newState;
    }),
  clone: createReducer<AssetRevision|null, AssetToCloneActions>(null)
    .handleAction(cloneActions.set, (state, action) => action.payload)
    .handleAction(cloneActions.clear, () => null)
});
const getAsArray = (s: CommonState) => filterAssetRevisions(selectors.getAsArray(s));
// hides rmx box router assets.
const filterHiddenAssets = false;
export const filterAssetRevisions = (revisions: AssetRevision[]) => filterHiddenAssets ? filterRelatedHidden(revisions, r => r.asset) : revisions;

export const assetRevisionStore = {
  selectors: {
    ...selectors,
    getAsArray: getAsArray,
    byInstallationRevisionId: selector(s => groupBy(getAsArray(s), (r: AssetRevision) => r.installationRevisionId)),
    getByInstallationRevisionId:
      selector(s => (id: number) => getAsArray(s).filter(r => r.installationRevisionId === id)),
    getClone: selector(s => selectors.getState(s).clone)
  },
  actions: {
    ...actions,
    upsert: (item: AssetRevision) => async (dispatch: CommonDispatch) => {
      const response: SaveInstallationRevisionEntityResponse = await saveAssetRevision(item);
      dispatchInstallationRevisionEntityActions(dispatch, getInstallationRevisionEntityUpsertActions(response));
    },
    delete: (id: string) => async (dispatch: CommonDispatch) => {
      const response: DeleteInstallationRevisionEntityResponse = await deleteAssetRevision(id);
      dispatchInstallationRevisionEntityActions(dispatch, getInstallationRevisionEntityDeleteActions(response));
    },
    cloneActions
  }
} as const;

export function cloneAsset(values: AssetRevision): AssetRevision {
  return setPropertyRecursively({
    ...values,
    asset: {
      ...values.asset,
      assetModules: [],
      name: '',
      uuid: getUUID(),
      specs: values.asset.specs.map<CurrentSpec>(s => ({...s, assetId: null, specValueId: 0, specValue: {...s.specValue, assetId: null}}))
    },
    children: values.children.map(c => ({...c, parentAssetRevisionId: null})),
    parents: values.parents.map(c => ({...c, childAssetRevisionId: null})),
    rmxBoxSourceAssignments: values.rmxBoxSourceAssignments
      .map(a => ({...a, assetRevisionId: null, assetModuleId: null, rmxBoxSourceId: null, digitalInput: null, analogInput: null})),
    alertSetPointOverrides: values.alertSetPointOverrides.map(a => ({...a, assetRevisionId: null, assetModuleId: null}))
  }, 'id', 0);
}

export const convertAssetRevisionsToOptions = (revs: AssetRevision[]) => convertToDropDownOptions(revs.map(r => ({id: r.id, name: r.asset.name})));
