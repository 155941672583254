import * as React from 'react';
import {Outlet} from 'react-router';
import NotFound from '../../NotFound/NotFound';
import {
  applyBasePaths,
  convertComponentPaths,
  manufacturerOperationalStatusLabelsPaths,
  RoutePaths
} from 'src/router/RoutePaths';
import {
  operationalStatusConversionPaths
} from './pages/operational-status/operationalStatusPaths';
import {OsType} from 'src/common/redux/entities/conversions/operational-statuses';
import {type AppRouteObject} from '../../../router/util';
import {lazy} from '../../../util/lazy';

interface GlobalConfigurationRoutesProps {
}

const basePath = RoutePaths.globalConfiguration;
const osCodeConversionPaths = Object.values(operationalStatusConversionPaths).map(path => path.replace(':osType', OsType.CodeConversion));
const osBitByBitConversionPaths = Object.values(operationalStatusConversionPaths).map(path => path.replace(':osType', OsType.BitByBitConversion));




export const globalConfigRoutes: AppRouteObject[] = [
  ...convertComponentPaths(
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.ManufacturerEditor), paths: Object.values(RoutePaths.manufacturerPaths)},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.DealerEditor), paths: Object.values(RoutePaths.dealerConfigPaths)},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.CompanyEditor), paths: Object.values(RoutePaths.globalCompanyConfigurationPaths)},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.AssetTemplateEditor), paths: Object.values(RoutePaths.assetTemplatePaths)},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.SpTemplateEditor), paths: Object.values(RoutePaths.spTemplatePaths)},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.AssetGroupEditor), paths: Object.values(RoutePaths.assetGroupPaths)},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.AssetTypeEditor), paths: Object.values(RoutePaths.assetTypePaths)},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.SpTypeEditor), paths: Object.values(RoutePaths.systemProcessTypePaths)},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.ComponentEditor), paths: Object.values(RoutePaths.componentPaths)},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.AlertTypeEditor), paths: Object.values(RoutePaths.alertTypePaths)},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.AlertDefinitionEditor), paths: Object.values(RoutePaths.dpaAlertPaths)},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.AlertDefinitionEditor), paths: Object.values(RoutePaths.ndpaAlertPaths)},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.AlertConversionEditor), paths: Object.values(RoutePaths.alertCodeConversionPaths)},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.AlertConversionEditor), paths: Object.values(RoutePaths.alertBitByBitConversionPaths)},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.AlertConversionEditor), paths: Object.values(RoutePaths.alertOneToOneConversionPaths)},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.AlertBitByBitAssignmentGroupEditor), paths: Object.values(RoutePaths.alertBitByBitAssignmentGroupPaths)},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.DataPointEditorRouter), paths: Object.values(RoutePaths.dataPointPaths)},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.ManufacturerOperationalStatusLabelEditor), paths: Object.values(manufacturerOperationalStatusLabelsPaths)},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.OperationStatusConversionEditor), paths: osCodeConversionPaths},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.OperationStatusConversionEditor), paths: osBitByBitConversionPaths},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.RmxBoxEditor), paths: applyBasePaths(basePath, ...Object.values(RoutePaths.rmxBoxPaths))},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.ImportRmxBoxesPage), paths: [RoutePaths.importRmxBoxes]},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.GenerateCertificatesPage), paths: [RoutePaths.generateCertificates]},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.RmxBoxPortSettingsEditor), paths: applyBasePaths(basePath, ...Object.values(RoutePaths.rmxBoxPortSettingsPaths))},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.RmxBoxSourceEditor), paths: applyBasePaths(basePath, ...Object.values(RoutePaths.rmxBoxSourcePaths))},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.ConnectionKitEditor), paths: Object.values(RoutePaths.connectionKitPaths)},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.ProtocolAdapterEditor), paths: Object.values(RoutePaths.protocolAdapterPaths)},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.ControllerTypeEditor), paths: Object.values(RoutePaths.controllerTypePaths)},
    {lazy: lazy(import('./GlobalConfigRoute'), s => s.ControllerTypeRevisionEditor), paths: Object.values(RoutePaths.controllerTypeRevisionPaths)},
  ),
  {index: true, lazy: async () => {
    const Component = (await import('../BlankPage/BlankPage')).default;
    return ({element: <Component /> });
    }},
  {path: '*', element: <NotFound />}
];

const GlobalConfigurationRoutes = (props: GlobalConfigurationRoutesProps) => (
  <Outlet/>
);

export default GlobalConfigurationRoutes;
