export type ConvertToItems<T> = {
  [key: number]: T;
};

export type ValueOf<T> = T[keyof T];

export const isOfType = <T>(
  varToBeChecked: any,
  propertyToCheckFor: keyof T
): varToBeChecked is T =>
  (varToBeChecked as T)[propertyToCheckFor] !== undefined;

export function emptyIfFalsy<T>(items: T[]) {
  return (!items ? [] : items);
}

export type OmitKeys<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

// Very useful for swapping out property definitions of an object.
export type ReplaceProperties<T, U extends {
  [P in keyof T]?: U[P];
} & { [K in Exclude<keyof U, keyof T>]: never }> = OmitKeys<T, keyof T & keyof U> & U;

export type MarkPropertiesNullable<T, TKey extends keyof T> = {
  [K in keyof T]: K extends TKey ? T[K] | null : T[K];
};
