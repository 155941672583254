import * as React from 'react';
import { type CSSProperties, type ReactNode, type SyntheticEvent } from 'react';
import { type ButtonProps, Button } from '../../Controls/Button/Button';
import { Spinner } from '../../Controls';

interface LoadingButtonProps {
  loading: boolean;
  label: string | ReactNode;
  className?: string;
  type?: ButtonProps['type'];
  variant?: ButtonProps['variant'];
  disabled?: boolean;
  onClick?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  style?: CSSProperties;
  size?: 'sm' | 'lg' | undefined;
  title?: string;
}

export const LoadingButton = ({size,variant, onClick, className, disabled, label, loading, type, style, title}: LoadingButtonProps) => (
  <Button
    title={title}
    size={size}
    disabled={loading || disabled}
    variant={variant ?? 'primary'}
    type={type || 'button'}
    className={className}
    onClick={onClick}
    style={style}
  >
    { loading ?
      <Spinner
        as='span'
        animation='border'
        size='sm'
        role='status'
        aria-hidden='true'
      /> : <>{label}</>}
  </Button>
);
