/*eslint-disable */
import type { AssetDetails } from '../menu-modal/MenuModal/portal-asset-context';
import type { SiteFormValues } from '../configuration/SiteEditorModal';
import { createPortalAxios } from '../utils/ajax';
import type { AxiosInstance } from 'axios';
export type SiteDropDownOptionListResponse = { siteName: string; siteUuid: string }[];
import type { OrgLevel } from '../../api/userDashboardApi';
import type { DataTableResponse } from '../shared-compat/DataTable/hooks';
import type { ActiveAlertRow } from '../menu-modal/MenuModal/portal-active-alert-context';
import type { BypassTableRow } from '../utils/BypassTable/BypassTableDefinition';
import { reportException } from '../../util/sentryProvider';

export type LocationDataResponse = {
  Countries: string[];
  StatesByCountry: Record<string, string[] | undefined>;
};
export type PortalSiteSelectionList = {
  uuid: string;
  name: string;
};

export type SiteInfo = {
  name: string;
  address1: string;
  address2: string;
  city: string;
  country: string;
  state: string;
  postalcode: string;
  crmCustomerId: string | null;
};

export type BypassAction = 'Ignore' | 'Create Flag';

export interface CreateBypassRequest {
  id: number | string;
  otherReason: string;
  bypassAction: BypassAction;
  siteName?: string;
  assetModel?: string;
  assetSerial?: string;
}

export type EBypassType = 'TimedExpiry' | 'AutomaticClear' | 'ActivitySlam';

export interface ClearBypassRequest {}

class LegacyPortalApi {
  private portalAxios: AxiosInstance = createPortalAxios();
  // constructor(private api: AxiosInstance) {}

  async addSite(companyUuid: string, siteName: string) {
    const result = (
      await this.portalAxios.post<{ addsuccessful: boolean; message: string; siteid: string }>('/Location/AddSite', {
        CompanyGuid: companyUuid,
        SiteName: siteName
      })
    ).data;
    if (result.addsuccessful) {
      return result.siteid;
    } else {
      throw result.message;
    }
  }

  async editSite(companyUuid: string, siteUuid: string, formValues: SiteFormValues) {
    const payload = {
      CompanyID: companyUuid,
      OldSiteGuid: siteUuid,
      NewSiteName: formValues.name,
      Address1: formValues.address1,
      Address2: formValues.address2,
      City: formValues.city,
      Country: formValues.country,
      State: formValues.stateProvince,
      PostalCode: formValues.postalCode,
      CrmCustomerId: formValues.crmCustomerId
    };
    const oData = (await this.portalAxios.post<{ updatesuccessful: boolean; message: string }>('/Location/EditSite', payload)).data;
    if (oData.updatesuccessful) {
      return oData;
    } else {
      throw oData.message;
    }
  }

  async upsertSite(companyUuid: string, siteUuid: string | null, formValues: SiteFormValues) {
    const payload = {
      CompanyID: companyUuid,
      OldSiteGuid: siteUuid,
      NewSiteName: formValues.name,
      Address1: formValues.address1,
      Address2: formValues.address2,
      City: formValues.city,
      Country: formValues.country,
      State: formValues.stateProvince,
      PostalCode: formValues.postalCode,
      CrmCustomerId: formValues.crmCustomerId
    };
    const oData = (await this.portalAxios.post<{ updatesuccessful: boolean; message: string }>('/Location/UpsertSite', payload)).data;
    if (oData.updatesuccessful) {
      return oData;
    } else {
      throw oData.message;
    }
  }
  getSiteInfo(siteUuid: string) {
    return new Promise<SiteInfo>((resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: '/Location/GetSiteInfo',
        cache: false,
        data: {
          SiteID: siteUuid
        },
        success: function (oData) {
          resolve(oData);
        },
        error: function (result) {
          reject(result);
        }
      });
    });
  }

  getLocationData() {
    return new Promise<LocationDataResponse>((resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: '/Location/GetLocationData',
        cache: false,
        success: function (oData) {
          resolve(oData);
        },
        error: function (result) {
          reject(result);
        }
      });
    });
  }

  async getSiteList(companyUuid: string) {
    return (
      await this.portalAxios.post<SiteDropDownOptionListResponse>('/Location/GetSiteDataList', {
        companyUuid: companyUuid
      })
    ).data;
  }

  GetAssetName(id: number): Promise<string> {
    return new Promise((resolve) => {
      $.ajax({
        type: 'GET',
        url: '/Alerts/GetAssetName',
        cache: false,
        data: { AssetID: id },
        success: function (oData) {
          resolve(oData.data);
        }
      });
    });
  }

  getSiteIntIdByGuid(uuid: string): Promise<number> {
    return new Promise((resolve) => {
      $.ajax({
        type: 'GET',
        url: '/Site/RmxId',
        cache: false,
        data: { uuid: uuid },
        dataType: 'json',
        success: function (oData) {
          resolve(oData);
        }
      });
    });
  }

  getInstallationName(companyName: string, siteName: string): Promise<string> {
    return new Promise((resolve) => {
      $.ajax({
        type: 'GET',
        url: '/Home/GetInstallationName',
        cache: false,
        data: {
          CompanyName: companyName,
          SiteName: siteName
        },
        success: function (oData) {
          resolve(oData.data);
        }
      });
    });
  }

  getAlertDetailsAsync(id: string, type: string): Promise<any> {
    return new Promise((resolve) => {
      $.ajax({
        url: type === 'Site' ? '/Alerts/GetSiteAlertsMouseHover' : '/Alerts/GetCompanyAlertsMouseHover',
        method: 'get',
        data: type === 'Site' ? { SiteID: id } : { CompanyID: id },
        success: function (oData: { havedata: boolean; data: string }) {
          if (oData.havedata) {
            resolve(oData);
          }
        },
        error: function (result) {}
      });
    });
  }

  getCompanyOrSiteNameAsync(id: string, type: string): Promise<string> {
    // console.log(`id: ${id}, type: ${type}`)
    return new Promise((resolve) => {
      $.ajax({
        type: 'GET',
        url: type === 'Site' ? '/Alerts/GetSiteName' : '/Alerts/GetCompanyName',
        cache: false,
        data: type === 'Site' ? { SiteID: id } : { CompanyID: id },
        success: function (oData: { data: string }) {
          resolve(oData.data);
        }
      });
    });
  }

  getAssetDetails(id: number): Promise<AssetDetails> {
    // console.log(`id: ${id}, type: ${type}`)
    return new Promise((resolve) => {
      $.ajax({
        type: 'GET',
        url: '/Alerts/GetAssetDetails',
        cache: false,
        data: { assetId: id },
        success: function (oData: AssetDetails) {
          resolve(oData);
        }
      });
    });
  }

  createBypass(request: CreateBypassRequest, orgLevel: OrgLevel, type: EBypassType = 'AutomaticClear') {
    if (orgLevel === 'Asset') {
      // if the asset is not monitored this will do nothing
      return this.portalAxios.get('/Alerts/ManageAsset', {
        params: {
          AssetID: request.id,
          IsOther: 1,
          IsAutomatic: 1,
          OtherReason: request.otherReason,
          BypassType: type,
          BypassAction: request.bypassAction
        }
      });
    } else if (orgLevel === 'Site') {
      return this.portalAxios.get('/Alerts/ManageSite', {
        params: {
          SiteId: request.id,
          IsOther: 1,
          IsAutomatic: 1,
          OtherReason: request.otherReason,
          BypassAction: request.bypassAction
        }
      });
    } else {
      reportError('Invalid type for bypass request');
      return Promise.reject('Invalid type for bypass request');
    }
  }

  clearAssetBypassByType(id: number, type: EBypassType) {
    return this.portalAxios.get('/Alerts/ClearManageAsset', {
      params: {
        AssetID: id,
        BypassType: type
      }
    });
  }

  clearSiteBypass(id: number) {
    return this.portalAxios.get('/Alerts/ClearManageSite', {
      params: {
        SiteId: id
      }
    });
  }

  async getIdByGuidAndType(guid: string, type: 'Company' | 'Site' | 'Asset') {
    return (await this.portalAxios.post('/Tiles/GetTargetId', { subjectId: guid, subjectType: type })).data;
  }

  async getActiveAlerts(searchValue: string, companyId: string, siteId: string, assetId: string): Promise<ActiveAlertRow[]> {
    const url = new URL('/AlertsV2/ActiveAlerts', window.location.origin);
    url.searchParams.append('companyId', companyId);
    url.searchParams.append('siteId', siteId);
    url.searchParams.append('assetUuid', assetId);
    return (
      await this.portalAxios.get(url.toString(), {
        params: { SearchParm: searchValue }
      })
    ).data;
  }

  async getCompanyManagementList(limit: number, skip: number, search: string) {
    return (await this.portalAxios.get<CompanyManagementResponse>('/Location/GetCompaniesGridListAll', { params: { limit, skip, search } })).data;
  }

  async getActiveBypassesAllCompanies(): Promise<BypassTableRow[]> {
    return (await this.portalAxios.get('/Alerts/GetActiveBypassesAllCompaniesRmx')).data;
  }

  async getActiveBypassesByInstallation(siteId: string): Promise<BypassTableRow[]> {
    return (await this.portalAxios.get('/Alerts/GetActiveBypassesByInstallationRmx', { params: { SiteID: siteId } })).data;
  }

  async getActiveBypassesByAsset(siteId: string, assetId: string): Promise<BypassTableRow[]> {
    return (await this.portalAxios.get('/Alerts/GetActiveBypassesByAssetRmx', { params: { SiteID: siteId, assetUuid: assetId } })).data;
  }

  async getActiveBypassesByCompany(companyId: string): Promise<BypassTableRow[]> {
    return (await this.portalAxios.get('/Alerts/GetActiveBypassesByCompanyRmx', { params: { CompanyID: companyId } })).data;
  }

  async OpenContactList(siteid: string) {
    try {
      const response = await this.portalAxios.get('/Alerts/GetPrimaryContactList', {
        params: {
          SiteID: siteid
        }
      });
      const oData = response.data;
      $('#data-table-contacts').empty();
      const data = JSON.parse(oData.data);
      if (data.length === 0) {
        alert('No contacts found for this site');
        return;
      }
      // dependent on this function being available in the global scope
      // @ts-ignore
      CreateTable(oData.data, 'contacts_grid', 'data-table-contacts', 'collapsed display wrap table table-striped table-bordered dtr-inline');

      $('#dialog-contacts').show();

      $(() => {
        $('#dialog-contacts').dialog({
          dialogClass: 'no-close',
          resizable: false,
          height: 'auto',
          width: 'auto',
          modal: true,
          buttons: {
            CLOSE: function () {
              $(this).dialog('close');
            }
          }
        });
      });
    } catch (e) {
      reportException(e);
    }
  }
}
type CompanyManagementResponse = CompanyManagementUserPermissions & {
  Count: number;
  Companies: CompanyManagementItem[];
};

export type CompanyManagementItem = {
  CompanyID: string;
  CompanyName: string;
  CompanyType: string;
  HasSites: boolean;
  HasUsers: boolean;
};
export type CompanyManagementUserPermissions = {
  IsAdministrator: boolean;
  IsDemoUser: boolean;
  IsGodMode: boolean;
  CanEditSites: boolean;
  CanAddSites: boolean;
  CanEditUsers: boolean;
  CanAddUsers: boolean;
  CanEditCompanies: boolean;
  UserType: 'Owner' | 'Partner' | 'Customer' | 'Activation Pending';
};

export function compareManagementPermissions(a: CompanyManagementUserPermissions, b: CompanyManagementUserPermissions) {
  return (
    a.IsAdministrator === b.IsAdministrator &&
    a.IsDemoUser === b.IsDemoUser &&
    a.IsGodMode === b.IsGodMode &&
    a.UserType === b.UserType &&
    a.CanEditSites === b.CanEditSites &&
    a.CanAddSites === b.CanAddSites &&
    a.CanAddUsers === b.CanAddUsers &&
    a.CanEditUsers === b.CanEditUsers &&
    a.CanEditCompanies === b.CanEditCompanies
  );
}

export function makeCompanyManagementPermissions<T extends CompanyManagementUserPermissions>(a: T): CompanyManagementUserPermissions {
  return {
    IsAdministrator: a.IsAdministrator,
    IsDemoUser: a.IsDemoUser,
    IsGodMode: a.IsGodMode,
    UserType: a.UserType,
    CanEditSites: a.CanEditSites,
    CanAddSites: a.CanAddSites,
    CanAddUsers: a.CanAddUsers,
    CanEditUsers: a.CanEditUsers,
    CanEditCompanies: a.CanEditCompanies
  };
}
export function getLegacyPortalApi() {
  return new LegacyPortalApi();
}
