import * as React from 'react';
import {createBrowserRouter, Outlet, RouterProvider} from 'react-router';
import NotFound from '../pages/NotFound/NotFound';
import Login from '../pages/Login/Login';
import {RoutePaths} from './RoutePaths';
import {LogOut} from '../pages/Logout/Logout';
import Dashboard from 'src/pages/Dashboard/DashboardLayout/Dashboard';
import {Home} from '../pages/Home/Home';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import UserManagement from '../pages/Configuration/UserManagement/UserManagement';
import {LazyGlobalConfiguration} from 'src/pages/Configuration/GlobalConfiguration/LazyGlobalConfiguration';
import {LazyCompanyConfiguration} from 'src/pages/Configuration/CompanyConfiguration/LazyCompanyConfiguration';
import {LazyCompanySelection} from 'src/pages/Configuration/CompanySelection/LazyCompanySelection';
import {type ComponentType} from 'react';
import {makePageWithHeader, makePageWithDynamicHeader} from 'src/components/util/PageWithHeader';
import {type HeaderChangeProp} from 'src/components/layout/PageHeader/PageHeader';
import {pipeLog} from '../util';
import {type AppRouteObject, convertToRouteObjects} from './util';
import {dashboardRoutes} from '../pages/Dashboard/DashboardRoutes';
import {globalConfigRoutes} from '../pages/Configuration/GlobalConfiguration/GlobalConfigurationRoutes';
import {companyConfigRoutes} from '../pages/Configuration/CompanyConfiguration/CompanyConfigurationRoutes';
import {userRoutes} from '../pages/Configuration/UserManagement/UserManagementRoutes';
import {lazy} from '../util/lazy';
import {ReactRouterErrorBoundary} from '../components/layout/AppErrorBoundary';
import { isDevMode } from 'src/env';

const GlobalConfiguration = makePageWithHeader(LazyGlobalConfiguration, {label: 'Global Configuration', removeMargin: true});
const CompanySelection = makePageWithHeader(LazyCompanySelection, {label: 'Company Configuration', removeMargin: true});
const CompanyConfiguration = makePageWithDynamicHeader(LazyCompanyConfiguration as unknown as ComponentType<HeaderChangeProp>, true);
const routes: AppRouteObject[] = [
  {path: RoutePaths.login, element: <Login />, public: true, wildcard: true},
  {path: RoutePaths.logout, element: <LogOut />, public: true},
  {path: RoutePaths.forgotPassword, element: <ForgotPassword />, public: true},
  {path: RoutePaths.resetPassword, element: <ResetPassword />, public: true},
  {element: <Home />, index: true},
  {path: RoutePaths.dashboard, element: <Dashboard />, children: dashboardRoutes},
  {path: RoutePaths.globalConfiguration, element: <GlobalConfiguration />, children: globalConfigRoutes},
  {path: RoutePaths.companySelection, element: <CompanySelection />},
  {path: RoutePaths.companyConfiguration, element: <CompanyConfiguration />, children: companyConfigRoutes},
  {path: RoutePaths.userManagement, element: <UserManagement />, children: userRoutes},
  {path: RoutePaths.profilePaths.View, lazy: lazy(import('../pages/Profile/Profile'), s => s.default)},
  {path: RoutePaths.profilePaths.Edit, lazy: lazy(import('../pages/Profile/Profile'), s => s.default)},
  {path: '*', element: <NotFound/>}
];

function Root() {
  return <Outlet />;
}


const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: pipeLog(convertToRouteObjects(routes), 'routes'),
    errorElement: !isDevMode() && <ReactRouterErrorBoundary />
  }
]);

function AppRoutes() {
  return (
     <RouterProvider router={router} />
  );
}

export default AppRoutes;
