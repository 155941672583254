import {combineReducers} from 'redux';
import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../utils';
import {createStandardSelectors, getEntities} from '../selectors';
import type { ComponentDefinition } from '../../types/ComponentDefinition';


const actions = createStandardActions(placeholder<ComponentDefinition>(), 'COMPONENT_DEFINITION/SET', 'COMPONENT_DEFINITION/SAVE');
export type ComponentDefinitionActions = GetActions<typeof actions>;
export const componentDefinitions = combineReducers({items: standardItemsReducer<ComponentDefinition, ComponentDefinitionActions>(actions)});
export const componentDefinitionStore = {
  selectors: createStandardSelectors(placeholder<ComponentDefinition>(), s => getEntities(s).componentDefinitions),
  actions: {
    ...actions
  }
} as const;
